// draggable.directive.ts
import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appBsDraggable]',
})
export class DraggableDirective {
  private isDragging = false;
  private startX = 0;
  private startY = 0;
  private originalWidth: string;

  constructor(private el: ElementRef) {}

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent): void {
    this.isDragging = true;

    const modalDialog = this.el.nativeElement.closest('.modal-dialog');
    if (modalDialog) {
      // Preserve the original width and position
      this.originalWidth = modalDialog.style.width || `${modalDialog.offsetWidth}px`;
      modalDialog.style.width = this.originalWidth;

      // Calculate and set the initial position
      const rect = modalDialog.getBoundingClientRect();
      modalDialog.style.left = `${rect.left}px`;
      modalDialog.style.top = `${rect.top}px`;

      // Apply absolute positioning for dragging
      modalDialog.style.position = 'absolute';
      modalDialog.style.zIndex = '1055'; // Above the backdrop

      this.startX = event.clientX - rect.left;
      this.startY = event.clientY - rect.top;
    }
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent): void {
    if (!this.isDragging) return;

    const modalDialog = this.el.nativeElement.closest('.modal-dialog');
    if (modalDialog) {
      // Constrain modal to stay within the viewport
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      const left = Math.max(0, Math.min(event.clientX - this.startX, viewportWidth - modalDialog.offsetWidth));
      const top = Math.max(0, Math.min(event.clientY - this.startY, viewportHeight - modalDialog.offsetHeight));

      modalDialog.style.left = `${left}px`;
      modalDialog.style.top = `${top}px`;
    }
  }

  @HostListener('document:mouseup')
  onMouseUp(): void {
    this.isDragging = false;
  }
}