import {Component, OnInit, Input, HostListener} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import Swal from 'sweetalert2';
import * as LC from 'literallycanvas';
import {ScaleControlStyle} from '@agm/core/services/google-maps-types';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-diagram-stand',
  templateUrl: './diagram.component.html',
  styleUrls: ['./diagram.component.scss']
})
export class DiagramComponent implements OnInit {
  page_title = 'Inspection: Diagram';
  private apiSite = environment.apiSite;
  lc: any;
  job_id: number;
  letterPrefix = '';
  reportData = [];
  findingsData = [];
  uploadImage = {};

  external = false;

  activeColor = 'green';
  baseColor = '#ccc';
  overlayColor = 'rgba(255,255,255,0.5)';
  dragging = false;
  loaded = false;
  imageLoaded = false;
  imageSrc = '';
  selectedImage: any;
  jobId = '';

  constructor(
    private _router: Router,
    private router: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.router.params.subscribe(params => {
      if (params.id) {
        this.jobId = 'vector_'+params.id;
      }

    });
    this.loadLc();
    this.loadCustomText();

  }

  

  loadLc() {
    class RectangleWithXTool extends LC.tools.Tool {
      name: string;
      iconName: string;
      optionsStyle: null;
      startX: number | null = null;
      startY: number | null = null;
      shape: any = null;

      constructor(lc: any) {
        super(lc);
        this.name = 'RectangleWithX';
        this.iconName = 'rectangle-x'; // Use a custom icon if needed
        this.optionsStyle = null;
      }

      begin(x: number, y: number, lc: any) {
        this.startX = x;
        this.startY = y;

        // Create a rectangle shape
        this.shape = LC.createShape('Rectangle', {
          x: x,
          y: y,
          width: 0,
          height: 0,
          strokeWidth: lc.opts.defaultStrokeWidth,
          strokeColor: lc.getColor('primary'),
          fillColor: lc.getColor('secondary'),
          isClosed: true,
        });
      }

      continue(x: number, y: number, lc: any) {
        if (this.startX === null || this.startY === null) return;

        const width = x - this.startX;
        const height = y - this.startY;

        // Update the rectangle dimensions
        this.shape.width = width;
        this.shape.height = height;

        // Clear the canvas and redraw all shapes
        lc.repaintLayer('main'); // Repaint all saved shapes
        lc.ctx.save(); // Save canvas state

        // Draw only the rectangle dynamically
        lc.drawShapeInProgress(this.shape);

        lc.ctx.restore(); // Restore canvas state
      }

      end(x: number, y: number, lc: any) {
        if (this.startX === null || this.startY === null || !this.shape) return;

        const width = x - this.startX;
        const height = y - this.startY;

        // Save the rectangle
        lc.saveShape(this.shape, true);

        // Create and save the diagonal lines
        const diagonalLines = this.createDiagonalLines(
          this.startX,
          this.startY,
          this.startX + width,
          this.startY + height,
          lc.opts.defaultStrokeWidth,
          lc.getColor('primary')
        );

        diagonalLines.forEach((line) => lc.saveShape(line, true));

        // Reset tool state
        this.startX = null;
        this.startY = null;
        this.shape = null;
      }

      createDiagonalLines(x1: number, y1: number, x2: number, y2: number, strokeWidth: number, color: string) {
        return [
          LC.createShape('Line', {
            x1: x1,
            y1: y1,
            x2: x2,
            y2: y2,
            strokeWidth,
            color,
          }),
          LC.createShape('Line', {
            x1: x2,
            y1: y1,
            x2: x1,
            y2: y2,
            strokeWidth,
            color,
          }),
        ];
      }
    }

    LC.defineShape('DashedRectangle', {
      constructor: function (args) {
        this.x = args.x;
        this.y = args.y;
        this.width = args.width;
        this.height = args.height;
        this.strokeColor = args.strokeColor;
        this.strokeWidth = args.strokeWidth;
        this.dashPattern = args.dashPattern || [5, 5];
      },

      // Bounding rectangle for the dashed rectangle
      getBoundingRect: function () {
        return {
          x: this.x,
          y: this.y,
          width: this.width,
          height: this.height,
        };
      },

      // Render the dashed rectangle on the canvas
      draw: function (ctx) {
        ctx.save();
        ctx.setLineDash(this.dashPattern); // Set dash pattern
        ctx.strokeStyle = this.strokeColor;
        ctx.lineWidth = this.strokeWidth;
        ctx.strokeRect(this.x, this.y, this.width, this.height);
        ctx.restore();
      },

      // Convert the shape to JSON for saving
      toJSON: function () {
        return {
          x: this.x,
          y: this.y,
          width: this.width,
          height: this.height,
          strokeColor: this.strokeColor,
          strokeWidth: this.strokeWidth,
          dashPattern: this.dashPattern,
        };
      },

      // Recreate the shape from JSON
      fromJSON: function (data) {
        return LC.createShape('DashedRectangle', data);
      },
    });


    class RectangleWithDotted extends LC.tools.Tool {
      name: string;
      iconName: string;
      optionsStyle: null;
      startX: number | null = null;
      startY: number | null = null;
      shape: any = null;

      constructor(lc: any) {
        super(lc);
        this.name = 'RectangleWithDotted';
        this.iconName = 'rectangle-dot'; // Use a custom icon if needed
        this.optionsStyle = null;
      }
      begin(x, y, lc) {
        this.startX = x;
        this.startY = y;

        // Create a placeholder rectangle shape
        this.shape = LC.createShape('Rectangle', {
          x: x,
          y: y,
          width: 0,
          height: 0,
          strokeWidth: lc.opts.defaultStrokeWidth,
          strokeColor: lc.getColor('primary'),
          fillColor: null,
          isClosed: true,
        });
      }

      continue(x, y, lc) {
        if (this.startX === null || this.startY === null) return;

        const width = x - this.startX;
        const height = y - this.startY;

        // Update placeholder rectangle dimensions
        this.shape.width = width;
        this.shape.height = height;

        // Clear and redraw in-progress shapes
        lc.repaintLayer('main');
        lc.drawShapeInProgress(this.shape);
      }

      end(x, y, lc) {
        if (this.startX === null || this.startY === null || !this.shape) return;

        const width = x - this.startX;
        const height = y - this.startY;

        // Create a dashed rectangle
        const dashedRectangle = LC.createShape('DashedRectangle', {
          x: this.startX,
          y: this.startY,
          width: width,
          height: height,
          strokeColor: lc.getColor('primary'),
          strokeWidth: lc.opts.defaultStrokeWidth,
          dashPattern: [5, 5], // Customize dash pattern
        });

        // Save the dashed rectangle to the canvas
        lc.saveShape(dashedRectangle, true);

        // Reset tool state
        this.startX = null;
        this.startY = null;
        this.shape = null;
      }
    }

    class ImageTool extends LC.tools.Tool {
      name: string;
      iconName: string;
      optionsStyle: string;
      images: string[];
      selectedImage: string | null;

      constructor(lc: any) {
        super(lc);
        this.name = 'ImageTool';
        this.iconName = 'images'; // Tool icon name, used in toolbar
        this.optionsStyle = 'lc-options';
      }
    }


    this.lc = LC.init(document.getElementsByClassName('literally2')[0], {
      imageURLPrefix: 'assets/literallycanvas/img',
      strokeWidths: [2, 5, 10, 25],
      primaryColor: '#000',
      tools: [
        LC.tools.Pencil,
        LC.tools.Eraser,
        LC.tools.Line,
        LC.tools.Rectangle,
        LC.tools.Ellipse,
        LC.tools.Text,
        LC.tools.Polygon,
        LC.tools.Pan,
        RectangleWithXTool,
        ImageTool,
        RectangleWithDotted
      ],
      secondaryColor: 'transparent',
      backgroundColor: 'transparent',
      defaultStrokeWidth: 2
    });
    if(localStorage.getItem(this.jobId) != null){
      const snapshot = JSON.parse(localStorage.getItem(this.jobId));
      snapshot.shapes = snapshot.shapes.map((shape: any) => {
        if (shape.className === 'Image' && shape.data.imageSrc) {
          const img = new Image();
          img.src = shape.data.imageSrc;

          // Load the image and update its object
          img.onload = () => {
            shape.data.imageObject = img;
            try {
              this.lc.repaintAllLayers(); // Redraw after the image loads
            } catch (error) {
              console.error('Error during canvas redraw:', error);
            }
          };

          img.onerror = (error) => {
            console.error('Image failed to load:', shape.data.imageSrc, error);
          };

          shape.data.imageObject = img; // Assign the imageObject to the shape
        }
        return shape;
      });
      this.lc.loadSnapshot(snapshot);
    }
    this.setSelectShapeTool();

  }

  // Method to handle the image insertion
  addImageToCanvas(imageUrl: string) {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      const imgShape = LC.createShape('Image', {
        x: 100 + Math.random() * 50,  // Slight offset for new instances
        y: 100 + Math.random() * 50,
        width: img.width / 2,
        height: img.height / 2,
        image: img,
      });

      this.lc.saveShape(imgShape);
      this.setSelectShapeTool();  // Switch tool to SelectShape for dragging
    };
  }

  loadCustomText() {
    let optionString = '<option value=""></option>';
    let lettersString = '<option value=""></option>';
    let iconString = '<option value=""></option>';
    const letters = [
      'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I',
      'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R',
      'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
    ];
    const icons = [
      'assets/images/mouse.png', 'assets/images/bat.png', 'assets/images/ant.png'
    ];
    const arrayLength = this.findingsData.length;
    for (let i = 0; i < arrayLength; i++) {
      const strDropDown = this.findingsData[i].finding_type + this.findingsData[i].finding_finding;
      optionString += '<option value="' + strDropDown + '">' + strDropDown + '</option>';
    }

    // add a to z letters
    for (let j = 0; j < icons.length; j++) {
      iconString += '<option value="' + icons[j] + '">' + icons[j] + '</option>';
    }

    // add a to z letters
    for (let j = 0; j < letters.length; j++) {
      lettersString += '<option value="' + letters[j] + '">' + letters[j] + '</option>';
    }
    // tslint:disable-next-line:max-line-length
    optionString = `<style>
      .active-letter-prefix {
        background-color: #a6a6a6 !important;
      }
    </style>
    <div 
      id="textoptiondiv" 
      style="display: block; height: 30px; line-height: 31px; background-color: #f5f5f5; margin-left: 362px;margin-top: -30px;"
    >
      <button 
        class="letterPrefix btn btn-primary" 
        style="margin-left: 5px; padding: 0px 5px; font-size: 14px; background-color: #e9e9ed; border-color: #8f8f9d; color: #000; margin-top: -3px;" 
        data-value="1"
      >
        1
      </button>
      <button 
        class="letterPrefix btn btn-primary" 
        style="margin-left: 5px; padding: 0px 5px; font-size: 14px; background-color: #e9e9ed; border-color: #8f8f9d; color: #000; margin-top: -3px;" 
        data-value="2"
      >
        2
      </button>
      <button 
        class="letterPrefix btn btn-primary" 
        style="margin-left: 5px; padding: 0px 5px; font-size: 14px; background-color: #e9e9ed; border-color: #8f8f9d; color: #000; margin-top: -3px;" 
        data-value="3"
      >
        3
      </button>
      <button 
        class="letterPrefix btn btn-primary" 
        style="margin-left: 5px; padding: 0px 5px; font-size: 14px; background-color: #e9e9ed; border-color: #8f8f9d; color: #000; margin-top: -3px;" 
        data-value="4"
      >
        4
      </button>
      <button 
        class="letterPrefix btn btn-primary" 
        style="margin-left: 5px; padding: 0px 5px; font-size: 14px; background-color: #e9e9ed; border-color: #8f8f9d; color: #000; margin-top: -3px;" 
        data-value="5"
      >
        5
      </button>
      <select 
        id="lettersoptionselect" 
        style="margin-left: 5px;"
      >
        ${lettersString}
      </select>
      <button 
        id="selectNextLetter" 
        class="btn btn-primary" 
        style="margin-left: 5px; padding: 0px 5px; font-size: 14px; background-color: #e9e9ed; border-color: #8f8f9d; color: #000; margin-top: -3px;"
        >
        >
      </button>
    </div>
    <div id="imgOptions"
      style="display: block; height: 30px; line-height: 31px; background-color: #f5f5f5;"
      >
      <button 
        class="imgPicker btn btn-primary" 
        style="margin-left: 5px; padding: 0px 5px; font-size: 14px; background-color: #e9e9ed; border-color: #8f8f9d; color: #000; margin-top: -3px; " 
        data-value="assets/images/mouse.png"
      >
        <img style="width : 24px;" src="assets/images/mouse.png">
      </button>
      <button 
        class="imgPicker btn btn-primary" 
        style="margin-left: 5px; padding: 0px 5px; font-size: 14px; background-color: #e9e9ed; border-color: #8f8f9d; color: #000; margin-top: -3px;" 
        data-value="assets/images/bat.png"
      >
        <img style="width : 24px;" src="assets/images/bat.png">
      </button>
      <button 
        class="imgPicker btn btn-primary" 
        style="margin-left: 5px; padding: 0px 5px; font-size: 14px; background-color: #e9e9ed; border-color: #8f8f9d; color: #000; margin-top: -3px;" 
        data-value="assets/images/ant.png"
      >
        <img style="width : 24px;" src="assets/images/ant.png">
      </button>
      <button 
        class="imgPicker btn btn-primary" 
        style="margin-left: 5px; padding: 0px 5px; font-size: 14px; background-color: #e9e9ed; border-color: #8f8f9d; color: #000; margin-top: -3px;" 
        data-value="assets/images/tree.png"
      >
        <img style="width : 18px;" src="assets/images/tree.png">
      </button>
      </div>
  `;


    $('.literally.toolbar-at-top .lc-options').append(optionString);

    $('.literally.toolbar-at-top .lc-options').find('.lc-font-settings').remove();
    
    if (this.lc.tool.name !== 'Text') {
      $('#textoptiondiv').hide();
    }
    if (this.lc.tool.name !== 'ImageTool') {
      $('#imgOptions').hide();
    }

    const unsubscribe = this.lc.on('toolChange', function ({tool}) {
      console.log(tool.name);
      if (tool.name === 'Text') {
        $('#imgOptions').hide();
        $('#textoptiondiv').show();
        $('#textoptiondiv').css('margin-top',"-30px");
        $('#textoptiondiv').css('margin-left',"362px");
     
        const selectedValue = $('#textoptionselect').val();
        if (selectedValue === '') {
          tool.dropdownText = false;
        } else {
          tool.dropdownText = true;
        }
        tool.setText(selectedValue);
        $('.imgPicker').removeClass('active-letter-prefix');
        $('.letterPrefix').show();
        $('#selectNextLetter').show();
        $('#lettersoptionselect').show();
      } else if ( tool.name === 'ImageTool'){
        $('#imgOptions').show();
        $('#textoptiondiv').hide();
      } else if ( tool.name === 'SelectShape'){
        $('#imgOptions').show();
        $('#textoptiondiv').hide();
      } else {
        $('#textoptiondiv').hide();
        $('#imgOptions').hide();
      }


    });
    const that = this.lc;
    const thisthat = this;
    $('#textoptionselect').on('change', function () {
      if (that.tool.name === 'Text') {
        $('.letterPrefix').removeClass('active-letter-prefix');
        thisthat.letterPrefix = '';
        const selectedValue = $('#textoptionselect').val();
        if (selectedValue === '') {
          that.tool.dropdownText = false;
        } else {
          that.tool.dropdownText = true;
        }
        that.tool.setText(selectedValue);
      }
    });
    $('.letterPrefix').on('click', function () {
      $('.letterPrefix').removeClass('active-letter-prefix');
      $(this).addClass('active-letter-prefix');
      thisthat.letterPrefix = $(this).attr('data-value');
      thisthat.setCustomText();
    });
    $('#lettersoptionselect').on('change', function () {
      thisthat.setCustomText();
    });

    $('#selectNextLetter').on('click', function () {
      const optionSelected = $('#lettersoptionselect > option:selected');
      optionSelected.removeAttr('selected');
      optionSelected.next('option').attr('selected', 'selected');
      thisthat.setCustomText();
    });
    $('.imgPicker').on('click', function () {
      $('.imgPicker').removeClass('active-letter-prefix');
      $(this).addClass('active-letter-prefix');
      const value = $(this).attr('data-value');
      thisthat.setCustomImage(value);
     });
  }
  setCustomImage(value) {
    const that = this.lc;
    const thisthat = this;
    const img = new Image();
    img.src = value; // Assuming selectedValue is the image URL

    img.onload = function () {
      // Add the image to the canvas
      const imgShape = LC.createShape('Image', {
        x: 100, // X position of the image
        y: 100, // Y position of the image
        width: img.width, // Image width
        height: img.height, // Image height
        image: img,
      });

      // Add the shape to the canvas
      that.saveShape(imgShape);
      thisthat.setSelectShapeTool();
    };
  }
  setSelectShapeTool(): void {
    const selectShapeTool = new LC.tools.SelectShape(this.lc);
    this.lc.setTool(selectShapeTool);
  }
  setCustomText() {
    const that = this.lc;
    const thisthat = this;
    if (that.tool.name === 'Text') {
      let selectedValue = $('#lettersoptionselect').val();
      if (selectedValue === '') {
        that.tool.dropdownText = false;
      } else {
        that.tool.dropdownText = true;
      }
      selectedValue = thisthat.letterPrefix + selectedValue;
      that.tool.setText(selectedValue);
    }
  }

  upload_diagram(lc) {
    const snapshot = JSON.stringify(lc.getSnapshot());
    this.draw_border(lc);
    const image = lc.getImage().toDataURL();
    const img = new Image();
    img.src = image;
    let that = this;
    img.onload = function () {
        // Get the width and height of the LiterallyCanvas image
        const width = img.width;
        const height = img.height;

        // Create a new canvas with the same dimensions
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;

        // Get the context of the canvas
        const ctx = canvas.getContext("2d");

        // Fill the new canvas with a white background
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, width, height);

        // Draw the LiterallyCanvas image on top of the white background
        ctx.drawImage(img, 0, 0, width, height);

        // Get the merged image as a Data URL
        const mergedImage = canvas.toDataURL();
        that.downloadBase64Image(mergedImage, 'image_dg.jpg');

        // Do something with the merged image (e.g., download, display, etc.)
    };
    
  }

  downloadBase64Image(base64Data: string, fileName: string): void {
    // Convert Base64 to a Blob
    const byteString = atob(base64Data.split(',')[1]);
    const mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0];
    const byteNumbers = new Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      byteNumbers[i] = byteString.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeString });

    // Create a temporary link element to trigger the download
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = fileName;

    // Append the link to the document, trigger click, and remove the link
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Release the object URL
    URL.revokeObjectURL(url);
  }


  draw_border(lc) {
    // console.log(shapes);
    const shapes = lc.shapes;
    const len = shapes.length;
    if (len < 2) {
      return;
    }
    let beforeRect;
    beforeRect = shapes[0].getBoundingRect();

    let xMin, xMax, yMin, yMax, width, height;
    xMin = xMax = beforeRect.x;
    yMin = yMax = beforeRect.y;
    for (let i = 0; i < len; i++) {
      const rect = shapes[i].getBoundingRect();
      let x = rect.x;
      let y = rect.y;

      if (x < xMin) {
        xMin = x;
      }
      if (x > xMax) {
        xMax = x;
      }
      if (y < yMin) {
        yMin = y;
      }
      if (y > yMax) {
        yMax = y;
      }
      x = rect.x + rect.width;
      y = rect.y + rect.height;

      if (x < xMin) {
        xMin = x;
      }
      if (x > xMax) {
        xMax = x;
      }
      if (y < yMin) {
        yMin = y;
      }
      if (y > yMax) {
        yMax = y;
      }

    }
    width = xMax - xMin;
    height = yMax - yMin;
    // alert("here");
    // createShape('Rectangle', {x, y, width, height, strokeWidth, strokeColor, fillColor})
    if (
      xMin !== beforeRect.x
      || yMin !== beforeRect.y
      || width !== beforeRect.width
      || height !== beforeRect.height
    ) {
      xMin -= 5;
      yMin -= 5;
      width += 10;
      height += 10;
    }

    lc.saveShape(LC.createShape('Rectangle', {
      x: xMin,
      y: yMin,
      width: width,
      height: height,
      stokeWidth: 1,
      strokeColor: 'white',
      fillColor: 'transparent'
    }));
  }

  saveDiagram() {
    if (this.lc.tool.name === 'Text') {

      if (this.lc.tool.currentShapeState === 'selected' || this.lc.tool.currentShapeState === 'editing') {

        Swal({
          title: 'Important information',
          text: 'There is not committed text in the diagram. Are you sure you want to save this text into the diagram?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        })
          .then((result) => {
            if (result.value) {
              this.upload_diagram(this.lc);
            }
          });

      } else {
        this.upload_diagram(this.lc);
      }
    } else {
      this.upload_diagram(this.lc);
    }
  }

  
  

  handleDragEnter() {
    this.dragging = true;
  }

  handleDragLeave() {
    this.dragging = false;
  }

  handleDrop(e) {
    e.preventDefault();
    this.dragging = false;
    this.handleInputChange(e);
  }

  handleImageLoad() {
    this.imageLoaded = true;
  }

  handleInputChange(e) {
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];

    const pattern = /image-*/;
    const reader = new FileReader();

    if (!file.type.match(pattern)) {
      Swal(
        'Invalid Format!',
        'Please select a JPG or PNG image.',
        'error'
      );
      return;
    }

    this.loaded = false;

    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  _handleReaderLoaded(e) {
    const reader = e.target;
    this.imageSrc = reader.result;
    this.loaded = true;
  }
}
